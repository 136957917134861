<template>
	<div class="c-app">
		<TheSidebar />
		<CWrapper>
			<TheHeader />
			<div class="c-body">
				<main class="c-main">
					<CContainer fluid>
						<transition name="fade" mode="out-in">
							<router-view :key="$route.path" />
						</transition>
					</CContainer>
				</main>
			</div>
			<TheFooter />
			<ImportContainer />
		</CWrapper>
	</div>
</template>

<script>
export default {
	name: "TheContainer",
	components: {
		TheSidebar: () => import("./TheSidebar"),
		TheHeader: () => import("./TheHeader"),
		TheFooter: () => import("./TheFooter"),
		ImportContainer: () => import("./ImportContainer"),
	},

	watch: {
		"$store.state.modalShow": function (val) {
			if (val) {
				document.body.classList.add("modal-open")
			} else document.body.classList.remove("modal-open")
		},
	},
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>
